import $ from 'jquery';
import SunCalc from 'suncalc';

import { gSettings } from './Settings';
import { getAjaxRequest } from './AjaxHelper';

import View from './View';
import DateHelpers from './DateHelpers';
import WeatherInfo from './WeatherInfo';
import { getCoordinateString } from './CoordinateConverter';

export class WeatherView extends View {
    constructor(lat,lon) {
        super();
        this.lat = lat.toFixed(6);
        this.lon = lon.toFixed(6);
    }

    get header() {
        return "Weather and Sunrise Info";
    }

    //lets make each view have this function, and call it when the sidebar opens
    populatePage() {
        let self = this;
        var script = "getWeatherInformation.php"
        var ajaxRequest = getAjaxRequest();  // The variable that makes Ajax possible!

        if(ajaxRequest != null) {
            ajaxRequest.onreadystatechange = function () {
                if (ajaxRequest.readyState == 4) {
                    var weatherInfo = new WeatherInfo(ajaxRequest.responseText);
                    // console.log(weatherInfo);
                    self.populateWeatherFields(weatherInfo);   
                    self.populateSunriseFields();
                    $('#weatherView').show();
                    $('#rightSidebarSpinner').hide();
                }
            }

            var timeInSeconds = Math.round((new Date()).getTime() / 1000);
            var queryString = "?lat=" + this.lat + "&lon=" + this.lon + "&request_time=" + timeInSeconds;
            ajaxRequest.open("GET", script + queryString, true);
            ajaxRequest.send(null);
        }
    }   

    populateSunriseFields() {
        var times = SunCalc.getTimes(new Date(), this.lat, this.lon)
        $('.sidebar #weatherView_sunrise').html(DateHelpers.getTimeAsString(times.sunrise));
        $('.sidebar #weatherView_sunset').html(DateHelpers.getTimeAsString(times.sunset));
    }

    populateWeatherFields(weatherInfo) {
        //set wind direction icon/rotation

        $('#locationText').html('Location: ' + getCoordinateString(weatherInfo.latitude, weatherInfo.longitude));
        var weatherUpdated = weatherInfo.weatherDate != null ? DateHelpers.stringFromDate(weatherInfo.weatherDate) : "?";
        $('#updatedTimeText').html('Weather Updated: ' + weatherUpdated);

        $('#weatherView_weatherDescription').html('<b>'+weatherInfo.weatherDescription.charAt(0).toUpperCase() + weatherInfo.weatherDescription.substring(1) + '</b>');
        if(weatherInfo.weatherImageUrl != null) {
            $('#weatherImage').show();
            $('#weatherImage').attr("src", weatherInfo.weatherImageUrl);
        } else {
            $('#weatherImage').hide();
        }
        $('#weatherView_humidity').html(weatherInfo.humidty+'%');
        $('#weatherView_cloudiness').html(weatherInfo.clouds+'%');

        if(gSettings.useMetric()) {
            let temp = weatherInfo.temperature != null ? weatherInfo.temperature + '°C' : "-";
            $('#weatherView_temperature').html('<b>'+ temp +'</b>');
            $('#weatherView_feelslike').html(weatherInfo.feelsLikeTemperature != null ? weatherInfo.feelsLikeTemperature + '°C' : "-");
            $('#weatherView_wind').html(weatherInfo.windSpeedKiloPerHour != null ? weatherInfo.windSpeedKiloPerHour.toFixed(0) + ' km/h ' + weatherInfo.windDirection : "-");
            $('#weatherView_pressure').html(weatherInfo.pressure != null ? (weatherInfo.pressure * 0.1).toFixed(1) + ' kPa' : "-");

            if(weatherInfo.snowMillimeters1h != null && weatherInfo.rainMillimeters1h != null && (weatherInfo.snowMillimeters1h + weatherInfo.rainMillimeters1h == 0) ) {
                $('#weatherView_precipitation_1h').html("0 mm");
            } else if(weatherInfo.snowMillimeters1h != null && weatherInfo.snowMillimeters1h > 0) {
                $('#weatherView_precipitation_1h').html(weatherInfo.snowMillimeters1h.toFixed(1) + ' mm of snow');
            } else if(weatherInfo.rainMillimeters1h != null && weatherInfo.rainMillimeters1h > 0) {
                $('#weatherView_precipitation_1h').html(weatherInfo.rainMillimeters1h.toFixed(1) + ' mm of rain');
            } else {
                $('#weatherView_precipitation_1h').html('-');
            }

            if(weatherInfo.snowMillimeters3h != null && weatherInfo.rainMillimeters3h != null && (weatherInfo.snowMillimeters3h + weatherInfo.rainMillimeters3h == 0) ) {
                $('#weatherView_precipitation_3h').html("0 mm");
            } else if(weatherInfo.snowMillimeters3h != null && weatherInfo.snowMillimeters3h > 0) {
                $('#weatherView_precipitation_3h').html(weatherInfo.snowMillimeters3h.toFixed(1) + ' mm of snow');
            } else if(weatherInfo.rainMillimeters3h != null && weatherInfo.rainMillimeters3h > 0) {
                $('#weatherView_precipitation_3h').html(weatherInfo.rainMillimeters3h.toFixed(1) + ' mm of rain');
            } else {
                $('#weatherView_precipitation_3h').html('-');
            }
        } 
        else {
            let temp = weatherInfo.temperature != null ? Math.round((weatherInfo.temperature*(9 / 5)) + 32) + '°F' : "-";
            $('#weatherView_temperature').html('<b>'+temp+'</b>');
            $('#weatherView_feelslike').html(weatherInfo.feelsLikeTemperature != null ? Math.round((weatherInfo.feelsLikeTemperature*(9 / 5)) + 32) + '°F' : "-");
            $('#weatherView_wind').html(weatherInfo.windSpeedKiloPerHour != null ? (weatherInfo.windSpeedKiloPerHour / 1.609344).toFixed(0) + ' mph ' + weatherInfo.windDirection : "-");
            $('#weatherView_pressure').html(weatherInfo.pressure != null ? weatherInfo.pressure.toFixed(0) + ' hPa' : "-");

            if(weatherInfo.snowMillimeters1h != null && weatherInfo.rainMillimeters1h != null && (weatherInfo.snowMillimeters1h + weatherInfo.rainMillimeters1h == 0) ) {
                $('#weatherView_precipitation_1h').html("0 in.");
            } else if(weatherInfo.snowMillimeters1h != null && weatherInfo.snowMillimeters1h > 0) {
                $('#weatherView_precipitation_1h').html((weatherInfo.snowMillimeters1h / 25.4).toFixed(1) + ' in. of snow');
            } else if(weatherInfo.rainMillimeters1h != null && weatherInfo.rainMillimeters1h > 0) {
                $('#weatherView_precipitation_1h').html((weatherInfo.rainMillimeters1h / 25.4).toFixed(1) + ' in. of rain');
            } else {
                $('#weatherView_precipitation_1h').html('-');
            }

            if(weatherInfo.snowMillimeters3h != null && weatherInfo.rainMillimeters3h != null && (weatherInfo.snowMillimeters3h + weatherInfo.rainMillimeters3h == 0) ) {
                $('#weatherView_precipitation_3h').html("0 in.");
            } else if(weatherInfo.snowMillimeters3h != null && weatherInfo.snowMillimeters3h > 0) {
                $('#weatherView_precipitation_3h').html((weatherInfo.snowMillimeters3h / 25.4).toFixed(1) + ' in. of snow');
            } else if(weatherInfo.rainMillimeters3h != null && weatherInfo.rainMillimeters3h > 0) {
                $('#weatherView_precipitation_3h').html((weatherInfo.rainMillimeters3h / 25.4).toFixed(1) + ' in. of rain');
            } else {
                $('#weatherView_precipitation_3h').html('-');
            }
        }

        if(weatherInfo.windDegreesBearing != null) {
            // console.log(360 - weatherInfo.windDegree);
            $('#weatherView_wind_dir_img').rotate(weatherInfo.windDegreesBearing)
            $('#weatherView_wind_dir_img').show();
        } else {
            $('#weatherView_wind_dir_img').hide();
        }
    }
}

export default WeatherView;

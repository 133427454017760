import $ from 'jquery';
import _ from 'underscore';
import L from 'leaflet';
import firebase from 'firebase';
import moment from 'moment';

import DateHelpers from './DateHelpers';
import Observable from './Observable';
import UnknownContact from './UnknownContact';
import Chat from './Chat';
import { FB_STARTUP_TYPE, FB_DEFAULT_MAP_FAVORITE_ID, FB_SELECTED_LEH_REGION,
  FB_SHOW_CWD_ZONES, FB_SHOW_CWD_DROPOFFS, FB_CURRENT_BASEMAP_WEB, FB_SHOW_DIRECTIONS_FOR_WAYPOINTS, 
  FB_SEASON_VISIBILITY_ARCHERY, FB_WAYPOINT_FILTER_PREFS, FB_WAYPOINT_SORT_TYPE, FB_HIGHLIGHT_SELECTED_WMU, 
  FB_SHOW_BOUNDARY_ON_ZOOM, COORDINATE_FORMAT,FB_IS_METRIC, FB_HUNTLOG_OPT_IN,
  FB_SEASON_VISIBILITY_MUZZLELOADER, FB_SEASON_VISIBILITY_TRAPPING, FB_SEASON_VISIBILITY_DRAW,
  FB_SEASON_VISIBILITY_CLOSED, FB_SEASON_VISIBILITY_YOUTH, FB_FREQUENCY_IN_SECONDS,
  FB_LOCATION_DEVICE, FB_LOCATION_ACCURACY, MESSAGE_TYPE_NOTIFICATION, 
  FB_LAST_WAYPOINT_IMAGE,FB_LAST_WAYPOINT_BACKGROUND, FB_TYPE_WAYPOINT, FB_TYPE_TRACKEDWAYPOINT, FB_TYPE_DRAWNWAYPOINT,
  MESSAGE_TYPE_WAYPOINT
} from './FirebaseAttributes';
import { isProduction } from './Environment';
import { getBrowserInfo } from './minimumrequirements';
import { getPurchasesString } from './PurchaseHelper';
import { decryptJSON, encryptJSON, uuidv4 } from './Encryption';
import bootbox from 'bootbox';

import { gSettings, MapStartupType } from './Settings';
import TrackedWaypoint from './TrackedWaypoint';
import DrawnWaypoint from './DrawnWaypoint';
import Waypoint from './Waypoint';

const EVENT = {
    ACTIVE_PROVINCE: 'active-province',
    NO_ACTIVE_PROVINCE: 'no-active-province',

    UPDATE_PURCHASE: 'update-purchase',

    //COUNTY_MAP:         'county-map-update',
    //SUBSCRIPTION_MAP:   'subscription-map-update',

    // User map events
    CREATE_USER_MAP: 'create-user-map',
    UPDATE_USER_MAP: 'update-user-map',
    DELETE_USER_MAP: 'delete-user-map',

    // Contact events
    CREATE_CONTACT: 'create-contact',
    UPDATE_CONTACT: 'update-contact',
    DELETE_CONTACT: 'delete-contact',
    CREATE_INCOMING_CONTACT: 'create-incoming-contact-request',
    DELETE_INCOMING_CONTACT: 'delete-incoming-contact-request',
    CREATE_OUTGOING_CONTACT: 'create-outgoing-contact-request',
    DELETE_OUTGOING_CONTACT: 'delete-outgoing-contact-request',

    UPDATE_CONTACT_LOCATION: 'update-contact-location',
    SET_CONTACT_STATUS: 'set-contact-status',
    UPDATE_CONTACT_FREQUENCY: 'update-contact-frequency',
    REMOVE_CONTACT_MARKER: 'remove-contact-marker',

    // Chat events
    CREATE_CHAT:         'create-chat',
    UPDATE_CHAT:         'update-chat',
    DELETE_CHAT:         'delete-chat',
    UPDATE_CHAT_MESSAGE: 'update-chat-message',
    UPDATE_PROFILE_NAME: 'update-profile-name',
    UPDATE_PROFILE_PIC:  'update-profile-picture',

    // Waypoint events
    CREATE_WAYPOINT:         'create-waypoint',
    UPDATE_WAYPOINT:         'update-waypoint',
    DELETE_WAYPOINT:         'delete-waypoint',
    CREATE_DRAWN_WAYPOINT:   'create-drawn-waypoint',
    UPDATE_DRAWN_WAYPOINT:   'update-drawn-waypoint',
    DELETE_DRAWN_WAYPOINT:   'delete-drawn-waypoint',
    CREATE_TRACKED_WAYPOINT: 'create-tracked-waypoint',
    UPDATE_TRACKED_WAYPOINT: 'update-tracked-waypoint',
    DELETE_TRACKED_WAYPOINT: 'delete-tracked-waypoint',
};

// Data access layer to Firebase Realtime Database. Responsible for hooking live update events and 
// obstracting data storage schema from consumers. Will update gSettings directly, but otherwise 
// should dispatch Observable events to other data change consumers.
export default class FirebaseDatabase extends Observable {
    constructor(service) {
        super();

        this.service = service;
        this.db = firebase.database();

        this.onActiveProvince = this.createHandlerMethod(EVENT.ACTIVE_PROVINCE);
        this.onNoActiveProvince = this.createHandlerMethod(EVENT.NO_ACTIVE_PROVINCE);


        this.onPurchase = this.createHandlerMethod(EVENT.UPDATE_PURCHASE);

        this.onCreateUserMap = this.createHandlerMethod(EVENT.CREATE_USER_MAP);
        this.onUpdateUserMap = this.createHandlerMethod(EVENT.UPDATE_USER_MAP);
        this.onDeleteUserMap = this.createHandlerMethod(EVENT.DELETE_USER_MAP);

        this.onCreateContact = this.createHandlerMethod(EVENT.CREATE_CONTACT);
        this.onUpdateContact = this.createHandlerMethod(EVENT.UPDATE_CONTACT);
        this.onDeleteContact = this.createHandlerMethod(EVENT.DELETE_CONTACT);
        this.onCreateIncomingContactRequest = this.createHandlerMethod(EVENT.CREATE_INCOMING_CONTACT);
        this.onDeleteIncomingContactRequest = this.createHandlerMethod(EVENT.DELETE_INCOMING_CONTACT);
        this.onCreateOutgoingContactRequest = this.createHandlerMethod(EVENT.CREATE_OUTGOING_CONTACT);
        this.onDeleteOutgoingContactRequest = this.createHandlerMethod(EVENT.DELETE_OUTGOING_CONTACT);
        
        this.onUpdateContactLocation = this.createHandlerMethod(EVENT.UPDATE_CONTACT_LOCATION);
        this.onSetContactStatus = this.createHandlerMethod(EVENT.SET_CONTACT_STATUS);
        this.onUpdateContactFrequency = this.createHandlerMethod(EVENT.UPDATE_CONTACT_FREQUENCY);
        this.onRemoveContactMarker = this.createHandlerMethod(EVENT.REMOVE_CONTACT_MARKER);

        //this.onCreateChat = this.createHandlerMethod(EVENT.CREATE_CHAT);
        this.onUpdateChat = this.createHandlerMethod(EVENT.UPDATE_CHAT);
        this.onDeleteChat = this.createHandlerMethod(EVENT.DELETE_CHAT);
        this.onUpdateChatMessage = this.createHandlerMethod(EVENT.UPDATE_CHAT_MESSAGE);
        this.onUpdateProfileName = this.createHandlerMethod(EVENT.UPDATE_PROFILE_NAME);
        this.onUpdateProfilePicture = this.createHandlerMethod(EVENT.UPDATE_PROFILE_PIC);

        this.onCreateWaypoint = this.createHandlerMethod(EVENT.CREATE_WAYPOINT);
        this.onUpdateWaypoint = this.createHandlerMethod(EVENT.UPDATE_WAYPOINT);
        this.onDeleteWaypoint = this.createHandlerMethod(EVENT.DELETE_WAYPOINT);
        this.onCreateDrawnWaypoint = this.createHandlerMethod(EVENT.CREATE_DRAWN_WAYPOINT);
        this.onUpdateDrawnWaypoint = this.createHandlerMethod(EVENT.UPDATE_DRAWN_WAYPOINT);
        this.onDeleteDrawnWaypoint = this.createHandlerMethod(EVENT.DELETE_DRAWN_WAYPOINT);
        this.onCreateTrackedWaypoint = this.createHandlerMethod(EVENT.CREATE_TRACKED_WAYPOINT);
        this.onUpdateTrackedWaypoint = this.createHandlerMethod(EVENT.UPDATE_TRACKED_WAYPOINT);
        this.onDeleteTrackedWaypoint = this.createHandlerMethod(EVENT.DELETE_TRACKED_WAYPOINT);
    }

    setupListeners() {

        console.log("Setup Firebase listeners");
        this.migrateStartupMapRegions();
        this.setupPresenceListener();
        this.setupPurchaseListeners();
        this.setupPreferencesListeners();
        this.setupMapLayerListeners();
        this.setupContactListeners();
        this.setupConvoListeners();
        this.setupWaypointListeners();
        this.setupDrawnAndTrackedWaypointListeners();
        this.setupBroadcastViewingListeners();

        // TODO: Add favorites support to webapp
        //this.setupFavoritesListeners();
    }

    get user() {
        return this.service.user;
    }

    getUserNodeExists(uid) {
        return new Promise((resolve) => {
            var ref = this.db.ref("/users/" + uid + "/lowername");
            ref.once("value", (snapshot) => {
                resolve(snapshot.exists());
            });
        });
    }

    getUserAsContact() {
        return this.service.userContact;
    }
    
    updateUserNode(username) {
        return new Promise((resolve, reject) => {
            var name = username;
            if(name == null || name.trim() == "") {
                    var email = this.getEmail().toLowerCase();

                    if(email != null) {
                        if(email.includes("@")) {
                            var emailComponents = email.split("@");
                            var firstPart = emailComponents[0];
                            name = firstPart;
                        } else {
                            name = email;
                        }
                    } else {
                        name = "Unknown Name";
                    }
            }

            var json = {
                username: name,
                lowername: name.toLowerCase(),
                email: this.service.user.email.toLowerCase(),
                uid: this.user.uid,
                online_web: true 
            };

            var ref = this.db.ref("/users/" + this.user.uid);
            ref.update(json, () => {
                resolve();
            });
        })
    }


updateUserProfile(username) {
    if(username != null && username.trim() !== "") {
        this.user.updateProfile({
            displayName: username.trim()
        }).then(() => {
            //update successful;
            //this.updateUsername(username.trim());
            this.emit(EVENT.UPDATE_PROFILE_NAME, this.user.uid, this.user.displayName);

        }).catch(function(error) {
            console.log(error);
            //an error occured
        });
    }
}


updateProfilePicDate(uid, bIsForGroupChat) {
    if(bIsForGroupChat) {
        let ref = this.db.ref("/conversations/chats/"+uid+"/profilePicDate");
        ref.set(DateHelpers.stringFromDate(moment()));
        this.emit(EVENT.UPDATE_PROFILE_PIC, uid, bIsForGroupChat);
        //this.chatForChatKey(uid).refreshPicture();
    } else {
        console.log("updateProfilePicDate for user called");
        let ref = this.db.ref("/users/"+uid+"/profilePicDate");
        ref.set(DateHelpers.stringFromDate(moment()));
        this.service.updateUserContact(true);
    }
}

requestActiveProvince() {
    var ref = this.db.ref("sync/" + this.user.uid + "/web/");
    ref.once("value", (snapshot) => {
    let provinceCode = "";
    if(snapshot != null) {
        snapshot.forEach((child) => {
        if(child.key == "activeProvince") {
            provinceCode = child.val();
            this.emit(EVENT.ACTIVE_PROVINCE, provinceCode);
            return; // Should only be one activeProvince
        }
        });

        if(provinceCode.length === 0) {
            this.emit(EVENT.NO_ACTIVE_PROVINCE);
        }
    }
    });
}


getDefaultMapFavoriteID(provinceCode) {
    var ref = this.db.ref("sync/" + this.user.uid + "/preferences/" + provinceCode + "/" + FB_DEFAULT_MAP_FAVORITE_ID);
    return new Promise(function(resolve, reject) {
        ref.once("value", function(snapshot) {
            if(snapshot.exists()) {
                resolve(snapshot.val());
            } else {
                //no vis preference existed
                resolve(null);
            }
        }); 
    }); 
}

// getHuntLogParticipation(provinceCode) {
//     return new Promise(function(resolve, reject) {
//         var ref = this.db.ref("sync/" + this.user.uid + "/preferences/" + provinceCode + "/" + FB_HUNTLOG_OPT_IN);
//         ref.once("value", function(snapshot) {
//             if(snapshot.exists()) {
//                 resolve(snapshot.val());
//             } else {
//                 //no vis preference existed
//                 resolve(null);
//             }
//         }); 
//     }); 
// }

    setActiveProvince(provinceCode) {
        var ref = this.db.ref("sync/" + this.user.uid + "/web");
        ref.update({activeProvince: provinceCode});
    }

    setVisibilityForUserMapLayer(userMapLayer, bIsVisible) {
        var ref = this.db.ref("/sync/" + this.user.uid + "/maplayers/" + userMapLayer.getUUID() + "/visible");
        ref.set(bIsVisible);
    }

    setOpacityForUserMapLayer(userMapLayer, opacity) {
        var ref = this.db.ref("/sync/" + this.user.uid + "/maplayers/" + userMapLayer.getUUID() + "/opacity");
        ref.set(opacity);
    }

    setOpacityForCountyMap(mapName, opacity) {
        var ref = this.db.ref("/sync/"+this.user.uid+"/countymaps/"+gSettings.provinceCode +"/"+mapName+"/opacity");
        ref.set(opacity);
    }

    setBoundaryVisibilityForCountyMap(mapName, bIsVisible) {
        var ref = this.db.ref("/sync/"+this.user.uid+"/countymaps/"+gSettings.provinceCode +"/"+mapName+"/showBoundary");
        ref.set(bIsVisible);
    }


  activateWebPurchases() {
    var purchasesRef = this.db.ref("/purchases_web/" + this.user.uid + '/' + gSettings.provinceCode );
    return new Promise(function(resolve, reject) {
        purchasesRef.once("value", function(snapshot) {
            if(snapshot.exists()) {
                var purchases = snapshot.val();
                for(var sku in purchases) {
                    var purchaseID = purchases[sku];
                    gSettings.setProductPurchasedWeb(sku, purchaseID, true);
                }
            }
            resolve();
        }); 
    }); 
  }

  checkSeats() {
    var seatsRef = this.db.ref("/seats/"+this.user.uid+"/web");
    return new Promise((resolve, reject) => {
        seatsRef.once("value", (snapshot) => {

            var seats = snapshot.exists() ? snapshot.numChildren() : 0;
            if(seats < 3) { //0, 1, or 2 because we haven't updated for instance yet
                resolve(seats);
            } else {
                reject(seats);
            }
        });
    }); 
  }

  getStartupPref() {
    var ref = this.db.ref("sync/" + this.user.uid + "/preferences/" + gSettings.provinceCode + "/" + FB_STARTUP_TYPE);
    return new Promise((resolve, reject) => {
        ref.once("value", (snapshot) => {
            if(snapshot.exists()) {
                resolve(snapshot.val());
            } else {
                //no vis preference existed
                reject();
            }
        }); 
    }); 
  }

  getMapRegionByUUID(uuid) {
    return new Promise((resolve, reject) => {
        var ref = this.db.ref("sync/" + this.user.uid + "/favorites/" + gSettings.provinceCode + "/maps/" + uuid);
        ref.once("value", (snapshot) => {
            if(snapshot.exists()) {
                resolve(snapshot.val());
            } else {
                //no snapshot existed
                reject();
            }
        }); 
    }); 
  }

  getVisibilityPreferenceForMapLayer(mapLayer) {
    return new Promise((resolve, reject) => {
        var ref = this.db.ref("sync/" + this.user.uid + "/preferences/" + gSettings.provinceCode  + "/" + mapLayer.visibilityPreference);
        ref.once("value", (snapshot) => {
            if(snapshot.exists()) {
                resolve(snapshot.val());
            } else {
                //no vis preference existed
                reject();
            }
        }); 
    }); 
  }

  // Deprecated: see MapDataProvider
//   zoomToMapRegionForUUID(uuid) {
//     var ref = this.db.ref("sync/" + this.user.uid + "/preferences/" + gSettings.provinceCode + "/" + FB_STARTUP_MAP_REGIONS +"/" + uuid);
//     ref.once("value", (snapshot) => {
//         if(snapshot.exists()) {
//             var mapRegion = new MapRegion().initWithJson(snapshot.val()); //gets the uuid for the first key in 
//             if(mapRegion.isValid()) {
//                 zoomToLatLngBounds(mapRegion.bounds);
//             }
//         } else {
//             //no snapshot existed
//         }
//     }); 
//   }

  setupCountyMapsListeners(callback) {
    var ref = this.db.ref("/");
    if (this.user != null && gSettings.provinceCode) { 
        var preferencesRef = ref.child("sync/" + this.user.uid + "/" + "/countymaps/" + gSettings.provinceCode);
        preferencesRef.on("child_added", (snapshot) => {
          callback(snapshot);
        }, function(){}, this);

        preferencesRef.on("child_changed", (snapshot) => {
          callback(snapshot);
        }, function(){}, this);

        // preferencesRef.on("child_removed", function(snapshot) {
        // }, function(){}, this);
    }
  }

  getLEHPreference() {
    var ref = this.db.ref("sync/" + this.user.uid + "/preferences/" + gSettings.provinceCode + "/" + FB_SELECTED_LEH_REGION);
    return new Promise((resolve) => {
        if(gSettings.getPreference(FB_SELECTED_LEH_REGION) != null) {
            // console.log('reading from Settings');
            resolve(gSettings.getPreference(FB_SELECTED_LEH_REGION)); //get it from the settings if this has already been set
        } else {
            ref.once("value", (snapshot) => {
                if(snapshot.exists()) {
                    // console.log('reading from FB');
                    gSettings.setPreference(FB_SELECTED_LEH_REGION, snapshot.val());
                    resolve(snapshot.val());
                } else {
                    //no vis preference existed
                    gSettings.setPreference(FB_SELECTED_LEH_REGION, -1);
                    resolve(-1);
                }
            }); 
        }
    }); 
  }

  validateExpiryDate(expiryDate) {
    if(expiryDate.length <= 0) {
        return true;
    }
    else {
        var expirySeconds = parseInt(expiryDate); 
        if (isNaN(expiryDate)) {
            return true;
        }
        var d = new Date();
        var seconds = Math.round(d.getTime() / 1000);
        if(expirySeconds > seconds) {
            return true;
        }
    }

    return false;
  }

  activateIOSPurchases() {
    var outerThis = this;
    
    return new Promise(function(resolve, reject) {
        
        var purchaserIDRef = outerThis.db.ref("/users/" + outerThis.user.uid +"/purchaser_id_ios");
        purchaserIDRef.once("value", function(purchaserSnapshot) {
            if(purchaserSnapshot.exists()) {
                var purchaserID = purchaserSnapshot.val();
                var purchaserLinkedUserRef = outerThis.db.ref("/purchasers/" + purchaserID);
                purchaserLinkedUserRef.once("value", (purchaserUserSnapshot) => {
                    if(purchaserUserSnapshot.exists()) {
                        var purchaserLinkedUser = purchaserUserSnapshot.val();
                        if(purchaserLinkedUser === outerThis.user.uid) {
                            var purchasesRef = outerThis.db.ref("/purchases_ios/" + outerThis.user.uid + '/' + gSettings.provinceCode);
                            purchasesRef.once("value", (purchasesSnapshot) => {
                                if(purchasesSnapshot.exists()) {
                                    var purchasesIOS = purchasesSnapshot.val();

                                    // remove any that are expired
                                    for(var tempSku in purchasesIOS) {
                                        var sku = tempSku.replace(/,/g,".");
                                        var properties = purchasesIOS[tempSku];
                                        var expiryValue = "";
                                        if(properties && Object.prototype.hasOwnProperty.call(properties, "expirydate")) {
                                            expiryValue = properties["expirydate"];
                                        }

                                        if(outerThis.validateExpiryDate(expiryValue)) {
                                            gSettings.setProductPurchasedIOS(sku, true);
                                        }
                                        else {
                                            bootbox.alert("It looks like you may have purchased the subscription through Apple or Google within the iHunter mobile app. If your subscription is active on the mobile iHunter app, please open up iHunter on your mobile device to enable the subscription layers.");
                                            console.log('found an iOS purchase, but it is expired, so not activating');
                                        }   
                                    }
                                } 
                                resolve();    
                            });
                        }
                        else { resolve(); }
                    }
                    else { resolve(); }
                });
            } else { resolve(); }
        }); 
    }); 
  }

  activateAndroidPurchases() {
    var outerThis = this;
    
    return new Promise(function(resolve, reject) {
        var purchaserIDRef = outerThis.db.ref("/users/" + outerThis.user.uid +"/purchaser_id_android_"+gSettings.provinceCode);
        purchaserIDRef.once("value", function(purchaserSnapshot) {
            if(purchaserSnapshot.exists()) {
                var purchaserID = purchaserSnapshot.val();
                var purchaserLinkedUserRef = outerThis.db.ref("/purchasers/" + purchaserID);
                purchaserLinkedUserRef.once("value", (purchaserUserSnapshot) => {
                    if(purchaserUserSnapshot.exists()) {
                        var purchaserLinkedUser = purchaserUserSnapshot.val();
                        if(purchaserLinkedUser === outerThis.user.uid) {
                            var purchasesRef = outerThis.db.ref("/purchases_android/" + outerThis.user.uid + '/' + gSettings.provinceCode);
                            purchasesRef.once("value", (purchasesSnapshot) => {
                                if(purchasesSnapshot.exists()) {
                                    var purchasesAndroid = purchasesSnapshot.val();

                                    // remove any that are expired
                                    for(var tempSku in purchasesAndroid) {
                                        var sku = tempSku.replace(/,/g,".");

                                        var properties = purchasesAndroid[tempSku];
                                        var expiryValue = "";
                                        if(properties && Object.prototype.hasOwnProperty.call(properties, "expirydate")) {
                                            expiryValue = properties["expirydate"];
                                        }

                                        if(outerThis.validateExpiryDate(expiryValue)) {
                                            gSettings.setProductPurchasedAndroid(sku, true);
                                        }
                                        else {
                                            bootbox.alert("It looks like you may have purchased the subscription through Apple or Google within the iHunter mobile app. If your subscription is active on the mobile iHunter app, please open up iHunter on your mobile device to enable the subscription layers.");
                                            console.log('found an Android purchase, but it is expired, so not activating');
                                        } 
                                    }
                                } 
                                resolve();    
                            });
                        }
                        else { resolve(); }
                    }
                    else { resolve(); }
                });
            } else { resolve(); }
        }); 
    }); 
  }

  //this is to move the statupMapRegions as region favorites to be in line with the apps.
  migrateStartupMapRegions() {
    var mapStartupRef = this.db.ref("sync/" + this.user.uid + "/preferences/" + gSettings.provinceCode + "/StartupMapRegions/");
    mapStartupRef.on("child_added", (snapshot) => {
        var snapVal = snapshot.val();
        snapVal["FavoriteType"] = 0;
        var mapUID = snapshot.key;
        var newMapFavsNode = this.db.ref("sync/" + this.user.uid + "/favorites/" + gSettings.provinceCode  + "/maps/" + mapUID);
        newMapFavsNode.set(snapVal).then(() => {
            var removalRef = this.db.ref("sync/" + this.user.uid + "/preferences/" + gSettings.provinceCode + "/StartupMapRegions/" + mapUID);
            removalRef.remove();
        }, function(){}, this);

    }, function(){}, this);
  }

  setupPurchaseListeners() {
    let outerThis = this;
    var ref = this.db.ref("/");
    
    if (this.user != null) {     
        var userid = this.user.uid;
        var purchasesRef = ref.child("purchases_web/" + userid +"/"+gSettings.provinceCode);
        purchasesRef.on("child_added", (snapshot) => {
            var purchaseID = snapshot.val();
            var sku = snapshot.key;
            console.log("on child_added purchaseID: "+purchaseID+" sku: "+sku);
            gSettings.setProductPurchasedWeb(sku, purchaseID, true);

            this.emit(EVENT.UPDATE_PURCHASE, sku, 'web');

            console.log("purchases child_added "+ snapshot.key);
        });


        var purchasesRefIOS = ref.child("purchases_ios/" + userid+"/"+gSettings.provinceCode);
        purchasesRefIOS.on("child_added", (snapshot) => {
            // some ios skus contain .'s, so we change them to ,'s in FB, and convert them back here
            var sku = this.reconstructSku(snapshot.key);
            

            var purchaserIDRef = outerThis.db.ref("/users/" + outerThis.user.uid +"/purchaser_id_ios");
            purchaserIDRef.once("value", (purchaserSnapshot) => {
                if(purchaserSnapshot.exists()) {
                    var purchaserID = purchaserSnapshot.val();
                    //console.log("on child_added ios sku: "+sku+" purchaserID: "+purchaserID);

                    // check if the purchaserID is still pointing to our userid
                    var purchaserLinkedUserRef = ref.child("/purchasers/" + purchaserID);
                    purchaserLinkedUserRef.once("value", (purchaserUserSnapshot) => {
                        if(purchaserUserSnapshot.exists()) {
                            var purchaserLinkedUser = purchaserUserSnapshot.val();
                            if(purchaserLinkedUser === userid) {
                                
                                var properties = snapshot.val();
                                var expiryValue = "";
                                if(properties && Object.prototype.hasOwnProperty.call(properties, "expirydate")) {
                                    expiryValue = properties["expirydate"];
                                }

                                if(outerThis.validateExpiryDate(expiryValue)) {
                                    gSettings.setProductPurchasedIOS(sku, true);

                                    this.emit(EVENT.UPDATE_PURCHASE, sku, 'ios');
                                } 
                            } 
                        }
                    });
                }
            });

            console.log("purchases ios child_added "+ sku + " - note that this doesn't mean the purchase will show up if the purchaser isn't linked.");
        });

        var purchasesRefAndroid = ref.child("purchases_android/" + userid+"/"+gSettings.provinceCode);
        purchasesRefAndroid.on("child_added", (snapshot) => {
            // some ios skus contain .'s, so we change them to ,'s in FB, and convert them back here
            var sku = this.reconstructSku(snapshot.key);
            var purchaserIDRef = outerThis.db.ref("/users/" + outerThis.user.uid +"/purchaser_id_android_"+gSettings.provinceCode);
            purchaserIDRef.once("value", (purchaserSnapshot) => {
                if(purchaserSnapshot.exists()) {
                    var purchaserID = purchaserSnapshot.val();
                    //console.log("on child_added android sku: "+sku+" purchaserID: "+purchaserID);
        
                    // check if the purchaserID is still pointing to our userid
                    var purchaserLinkedUserRef = ref.child("/purchasers/" + purchaserID);
                    purchaserLinkedUserRef.once("value", (snapshot2) => {
                        if(snapshot2.exists()) {
                            var purchaserLinkedUser = snapshot2.val();
                            if(purchaserLinkedUser === userid) {

                                var properties = snapshot.val();
                                var expiryValue = "";
                                if(properties && Object.prototype.hasOwnProperty.call(properties, "expirydate")) {
                                    expiryValue = properties["expirydate"];
                                }

                                if(outerThis.validateExpiryDate(expiryValue)) {
                                    gSettings.setProductPurchasedAndroid(sku, true);

                                    this.emit(EVENT.UPDATE_PURCHASE, sku, 'android');
                                }
                            } 
                        }
                    });
                }
            });
            console.log("purchases android child_added "+ sku);
        });

        purchasesRef.on("child_removed", (snapshot) => {
            var purchaseID = snapshot.val();
            var sku = snapshot.key;
            gSettings.setProductPurchasedWeb(sku, purchaseID, false);

            this.emit(EVENT.UPDATE_PURCHASE, sku, 'web', false);
        });

        purchasesRefIOS.on("child_removed", (snapshot) => {
            var sku = this.reconstructSku(snapshot.key);
            gSettings.setProductPurchasedIOS(sku, false);
            console.log("purchases child_removed "+sku);

            this.emit(EVENT.UPDATE_PURCHASE, sku, 'ios', false);
        });

        purchasesRefAndroid.on("child_removed", (snapshot) => {
          var sku = this.reconstructSku(snapshot.key);
            gSettings.setProductPurchasedAndroid(sku, false);

            this.emit(EVENT.UPDATE_PURCHASE, sku, 'android', false);
        });
    }
  }

  setupPresenceListener() {
    var onlineRef = this.db.ref("/users/"+this.user.uid+"/online_web");
    var seatRef = this.db.ref("/seats/"+this.user.uid+"/web/"+this.getInstanceID());
    var ref = this.db.ref(".info/connected");

    ref.off();//not sure this is neccessary, but won't hurt
    ref.on("value", (snap) => {
        // If we're not currently connected, don't do anything.
        if(snap.val() === false) {
            return;
        }

        // Reference: https://firebase.google.com/docs/firestore/solutions/presence
        // If we are currently connected, then use the 'onDisconnect()' 
        // method to add a set which will only trigger once this 
        // client has disconnected by closing the app, 
        // losing internet, or any other means.
        onlineRef.onDisconnect().set(false).then(() => {
            // The promise returned from .onDisconnect().set() will
            // resolve as soon as the server acknowledges the onDisconnect() 
            // request, NOT once we've actually disconnected:
            // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

            // We can now safely set ourselves as 'online' knowing that the
            // server will mark us as offline once we lose connection.
            onlineRef.set(true);
        });

        seatRef.onDisconnect().remove().then(() => {
            seatRef.set(true);
        });

    });
  }

  getInstanceID() {
    if(!this.instanceID) {
        this.instanceID = uuidv4();            
    }
    return this.instanceID;
  }

    setupPreferencesListeners() {
        var ref = this.db.ref("/");
        if (this.user != null && gSettings.provinceCode) { 
            var preferencesRef = ref.child("sync/" + this.user.uid + "/preferences/" + gSettings.provinceCode + "/");
            preferencesRef.on("child_added", (snapshot) => {
                //gSettings.setPreference(snapshot.key, snapshot.val());
                this.processPreference(snapshot.key, snapshot.val());
            }, function(){}, this);

            preferencesRef.on("child_changed", (snapshot) => {
                //gSettings.setPreference(snapshot.key, snapshot.val());
                this.processPreference(snapshot.key, snapshot.val());
            }, function(){}, this);

            preferencesRef.on("child_removed", (snapshot) => {
                //gSettings.setPreference(snapshot.key, null);
                this.processPreference(snapshot.key, null);
            }, function(){}, this);


            // Why is this a special-case?
            var mapStartupRef = ref.child("sync/" + this.user.uid + "/favorites/" + gSettings.provinceCode + "/maps/");
            mapStartupRef.on("child_removed", (snapshot) => {
                gSettings.manager.removeMapStartupRegion(snapshot.key);
            }, function(){}, this);
            mapStartupRef.on("child_added", (snapshot) => {
                gSettings.manager.addMapStartupRegion(snapshot.key,  snapshot.val());
            }, function(){}, this);
        }
    }

    processPreference(key, val) {

        if(key == FB_SHOW_CWD_ZONES || key == FB_SHOW_CWD_DROPOFFS ||  
            key == FB_SHOW_DIRECTIONS_FOR_WAYPOINTS) {
                // ignore these; early exit
                gSettings.manager.setPreference(key, val);   
            return;
        }
        else if(key == FB_STARTUP_TYPE || key == FB_DEFAULT_MAP_FAVORITE_ID) {
            gSettings.manager.setPreference(key, val);            
        }
        else if(key == FB_SEASON_VISIBILITY_ARCHERY || key == FB_SEASON_VISIBILITY_MUZZLELOADER || key == FB_SEASON_VISIBILITY_TRAPPING ||
                key == FB_SEASON_VISIBILITY_DRAW || key == FB_SEASON_VISIBILITY_CLOSED || key == FB_SEASON_VISIBILITY_YOUTH) {
            gSettings.manager.updateSeasonSettings(key, val);            
    
        }else if(key == FB_IS_METRIC || key == FB_SHOW_BOUNDARY_ON_ZOOM) {
            gSettings.manager.updateInfoSettings(key, val);

        }else if(key == FB_HIGHLIGHT_SELECTED_WMU) {
            gSettings.manager.updateBoundaryPrefs(key, val);

        }else if(key == FB_WAYPOINT_SORT_TYPE) {
            gSettings.manager.updateWaypointSort(key, val);

        }else if(key == FB_WAYPOINT_FILTER_PREFS) {
            gSettings.manager.updateWaypointFilter(key, val);
        
        }else if(key == COORDINATE_FORMAT) {
            gSettings.manager.updateCoordFormat(key, val);
       
        }else if(key == FB_SELECTED_LEH_REGION) {
            gSettings.manager.updateSelectedRegion(key, val);
        
        }else if(key == FB_CURRENT_BASEMAP_WEB) {
            gSettings.manager.updateCurrentBasemap(key, val);

        }else if(key.includes("_colorpreference")) {
            gSettings.manager.updateColorPrefs(key, val);

        }else { //this preference should be a mapLayer visibility preference
            gSettings.manager.updateLayerPrefs(key, val);
        }
    }

    //Base Maps and user added map layers
    setupMapLayerListeners() {
        if (this.user != null) { 

            //this.addBuiltInMapLayers(); moved to MapDataProvider

            var ref = this.db.ref("sync/" + this.user.uid + "/maplayers/");
            ref.on("child_added", (snapshot) => {
                console.log("map layer added");
                //this.processUserMapLayer(snapshot);
                this.emit(EVENT.CREATE_USER_MAP, snapshot);
            }, function(){}, this);

            ref.on("child_changed", (snapshot) => {
                console.log("map layer changed");
                //this.processExistingUserMapLayer(snapshot);
                this.emit(EVENT.UPDATE_USER_MAP, snapshot);
            }, function(){}, this);

            ref.on("child_removed", (snapshot) => {
                console.log("map layer removed");
                //this.userMapLayerRemoved(snapshot);
                this.emit(EVENT.DELETE_USER_MAP, snapshot);
            }, function(){}, this);
        }
    }

    setUserMapLayer(userMapLayer) {
        var ref = this.db.ref("/sync/" + this.user.uid + "/maplayers/" + userMapLayer.getUUID());
        var updates = {};
        updates["json"] = userMapLayer.toJSON();
        updates["maptype"] = userMapLayer.overlayType; //base map or map overlay
        updates["visible"] = userMapLayer.bIsVisible;
        if(!userMapLayer.isBaseMap()) {
            updates["opacity"] = userMapLayer.opacity;
        }
        ref.set(updates);
    }

    replaceUserMapLayer(existingUserMapLayer, newUserMapLayer) {
        this.deleteUserMapLayer(existingUserMapLayer.uuid);
        _.delay(() => {
            this.setUserMapLayer(newUserMapLayer);
        },3000);
    }

    deleteUserMapLayer(uuid) {

        //delay a second just to make sure the visiblity setting doesn't somehow change after the deletion (it shouldn't either way, but just to be safe)
        _.delay(() => { 
            var ref = this.db.ref("sync/" + this.user.uid + "/maplayers/" + uuid);
            ref.remove();
        }, 1000);
    }

    deleteStartupMapRegion(uuid) {
        var ref = this.db.ref("sync/" + this.user.uid + "/favorites/" + gSettings.provinceCode  + "/maps/" + uuid);
        ref.remove();
    }

    setStartupMapRegion(mapRegion) {
        mapRegion.uuid = uuidv4();
        // console.log(mapRegion.toJSON());
        if(mapRegion.isValid()) {
            var ref = this.db.ref("sync/" + this.user.uid + "/favorites/" + gSettings.provinceCode  + "/maps/" + mapRegion.uuid);
            ref.set(mapRegion.toJSON()).then(function() {
                gSettings.updatePreference(FB_STARTUP_TYPE, MapStartupType.STARTUP_MAP_REGION);
                gSettings.updatePreference(FB_DEFAULT_MAP_FAVORITE_ID, mapRegion.uuid);
            });
        }
    }

  setupFavoritesListeners() {
    //agricultural leases //"sync/%s/favorites/%s/agleases";
    //waypoint favs //"sync/%s/favorites/%s/waypoints";
    //wmu favs //"sync/%s/favorites/%s/WMUs";
    //leh favs //"sync/%s/favorites/%s/LEHs";
    //map favs //"sync/%s/favorites/%s/maps";
    this.setupFavoritesListener("sync/"+this.user.uid+"/favorites/"+gSettings.provinceCode +"/maps");
  }

  setupFavoritesListener(path) {
    // if(this.user) {
    //     var ref = this.db.ref(path);
    //     // Attach an asynchronous callback to read the data at our posts reference
                   
    //     ref.on("child_added", function(snapshot) {
    //         var json = snapshot.val();
    //         var favType = json["FavoriteType"];
    //         if(favType == FavoriteType.MAP) {
    //             var mapRegion = new MapRegion().initWithJson(json);
    //             gMap.mapRegionFavorites.push(mapRegion);
    //             //we're not actually doing anything with these yet.
    //         } 
    //         else {
    //             //TODO
    //         }
    //     });
    

    //     ref.on("child_removed", function(snapshot) {
    //         var json = snapshot.val();
    //         var favType = json["FavoriteType"];
    //         if(favType == FavoriteType.MAP) {
    //             var mapRegion = new MapRegion();
    //             mapRegion.initWithJson(json);

    //             for(var i = 0; i < gMap.mapRegionFavorites.length; i++) {
    //                 var existingRegion = gMap.mapRegionFavorites[i];
    //                 if(existingRegion.uuid === mapRegion.uuid) {
    //                     gMap.mapRegionFavorites.splice(i, 1); 
    //                 }
    //             }
    //         } 
    //         else {
    //             //TODO
    //         }
    //     });
    // }
  }


  // Contacts
  setupContactListeners() {
      var incomingRequestRef = this.db.ref("/requests/" + this.user.uid + "/");     
      incomingRequestRef.on("child_added", function(snapshot) {
          var usersRef = this.db.ref("users/" + snapshot.key + "/");
          usersRef.once("value", function(snapshot) {
              if(snapshot != null) {
                  this.emit(EVENT.CREATE_INCOMING_CONTACT, snapshot.val());
                  // var contactJson = snapshot.val();
                  // this.addContactRequest(contactJson);                    
              }
          },this);
      },this);

      incomingRequestRef.on("child_removed", function(snapshot) {
          var usersRef = this.db.ref("users/" + snapshot.key + "/");
          usersRef.once("value", function(snapshot) {
              if(snapshot != null) {
                this.emit(EVENT.DELETE_INCOMING_CONTACT, snapshot.val());
                  // var contactJson = snapshot.val();
                  // this.removeContactRequest(contactJson);                    
              }
          },this);
      },this);

      var outgoingRequestRef = this.db.ref("/outgoingrequests/" + this.user.uid + "/");     
      outgoingRequestRef.on("child_added", function(snapshot) {
          var usersRef = this.db.ref("users/" + snapshot.key + "/");
          usersRef.once("value", function(snapshot) {
              if(snapshot != null) {
                this.emit(EVENT.CREATE_OUTGOING_CONTACT, snapshot.val());
                  // var contactJson = snapshot.val();
                  // this.addOutgoingRequest(contactJson);                    
              }
          },this);
      },this);

      outgoingRequestRef.on("child_removed", function(snapshot) {
          var usersRef = this.db.ref("users/" + snapshot.key + "/");
          usersRef.once("value", function(snapshot) {
              if(snapshot != null) {
                this.emit(EVENT.DELETE_OUTGOING_CONTACT, snapshot.val());
                  // var contactJson = snapshot.val();
                  // this.removeOutgoingRequest(contactJson);                    
              }
          },this);
      },this);

      //contact added
      var contactRef = this.db.ref("/contacts/" + this.user.uid + "/");     
      contactRef.on("child_added", function(snapshot) {
          var json = snapshot.val();
          var contactAccepted = json.accepted;

          var usersRef = this.db.ref("users/" + snapshot.key + "/");
          usersRef.once("value", function(snapshot) {
              if(snapshot != null) {
                  var contactJson = snapshot.val();
                  if(contactJson) {
                      contactJson.accepted = contactAccepted;
                      this.emit(EVENT.CREATE_CONTACT, snapshot.val());                      
                      // var bNewContact = true;
                      // var uid = contactJson.uid;
                      // var arrayLength = this.contacts.length;

                      // for (var i = 0; i < arrayLength; i++) {
                      //     var contact = this.contacts[i];
                      //     if(contact.uid === uid && !contact.isUnknownContact()) {
                      //         this.updateContact(contact);
                      //         bNewContact = false;
                      //         break;
                      //     }
                      // }
                      // if(bNewContact) {
                      //     this.addContact(contactJson);
                      // }        
                  }           
              }
          },this);
      },this);

          contactRef.on("child_changed", function(snapshot) {
          console.log("CONTACT CHANGED");

          var json = snapshot.val();
          var contactAccepted = json.accepted;

          var usersRef = this.db.ref("users/" + snapshot.key + "/");
          usersRef.once("value", function(snapshot) {
              if(snapshot != null) {
                  var contactJson = snapshot.val();
                  contactJson.accepted = contactAccepted;
                  this.emit(EVENT.UPDATE_CONTACT, contactJson);
                  //this.updateContact(contactJson);  
              }
          },this);
      },this);

      //contact deleted
      contactRef.on("child_removed", function(snapshot) {
          var json = snapshot.val();

          var usersRef = this.db.ref("users/" + snapshot.key + "/");
          usersRef.once("value", function(snapshot) {
              if(snapshot != null) {
                  var contactJson = snapshot.val();
                  this.emit(EVENT.DELETE_CONTACT, contactJson);
                  //this.removeContact(contactJson);  
              }
          },this);
      },this);
      
  }

  removeOutgoingContactRequest(uid) {
    var outgoingRequestRef = this.db.ref("/outgoingrequests/" + this.user.uid + "/" + uid);
    var contactRequestRef = this.db.ref("/requests/" + uid + "/" + this.user.uid);
    outgoingRequestRef.remove();
    contactRequestRef.remove();
  }


  // Chat
  setupConvoListeners() {
      var activeConvoRef = this.db.ref("/activeconversations/" + this.user.uid + "/");     
      activeConvoRef.on("child_added", (snapshot) => {
          var chatKey = snapshot.key;
          this.updateChatWithKey(chatKey, this.user.uid);
          this.listenToChat(chatKey);
          this.listenToMessagesForChat(chatKey);
      },this);

      activeConvoRef.on("child_removed", (snapshot) => {
          this.removeListenersForChatKey(snapshot.key);
          this.emit(EVENT.DELETE_CHAT, snapshot.key);
          //this.removeChat(snapshot.key);
      },this);
  }
  
  updateChatWithKey(chatKey, uid) {
      var ref = this.db.ref("conversations/chats/" + chatKey);
      ref.once("value", (snapshot) => {
          if(snapshot.exists()) {
            var chatNode = snapshot.val();
            this.emit(EVENT.UPDATE_CHAT, chatKey, uid, chatNode);
            //this.updateChatFromSnapshot(snapshot, userId, chatKey);
          }
      },this); 
  }

  listenToChat(chatKey) {
      var ref = this.db.ref("/conversations/chats/" + chatKey + "/");     
      ref.on("child_changed", (snapshot) => {
        this.updateChatWithKey(chatKey, this.user.uid);
      },this);
  }

  //HANDLE NOTIFCATIONS HERE
  listenToMessagesForChat(chatKey) {
      var self = this;
      var ref = this.db.ref("/conversations/messages/" + chatKey + "/").orderByKey().limitToLast(50); 
      ref.on("child_added", (snapshot) => {
          var lastViewedRef = this.db.ref("/conversations/chats/" + chatKey + "/lastviewed/");
          lastViewedRef.once("value", (lastViewedSnapshot) => {
              var lastViewedJson = lastViewedSnapshot.val();
              // var lastViewedDate = DateHelpers.dateFromString(lastViewedJson[this.user.uid]);
              var messageJson = snapshot.val();
              messageJson.chatkey = chatKey;
              messageJson.messageUID = snapshot.key;
              decryptJSON(messageJson, ["msg"], (decryptedJSON) => { 

                let lastViewed = lastViewedJson[this.user.uid];
                this.emit(EVENT.UPDATE_CHAT_MESSAGE, chatKey, decryptedJSON, lastViewed);

              });
              // var messageDate = DateHelpers.dateFromString(messageJson["time"]);
              // messageJson.chatkey = chatKey;
              // messageJson.messageUID = snapshot.key;
              // var chat = self.chatForChatKey(chatKey);
              // this.messageAdded(chat, messageJson, );

              // if(messageDate > lastViewedDate) {
              //     chat.addUnreadMessage();
              // }
          },this);
      },this);
  }

  //add 1 to numberOfMessages to account for endAt being inclusive of the ending message
    addMessagesToChat(numberOfMessages, chatkey, lastMessage) {
        var lastMessageTime = 0;
        var lastMessageKey = null;
        if(lastMessage) {
            var oldestVisibleMessage = lastMessage;
            lastMessageTime = oldestVisibleMessage.time;
            lastMessageKey = oldestVisibleMessage.messageUID;
        }


        var ref;
        if(lastMessageKey != null) {
            ref = this.db.ref("/conversations/messages/" + chatkey + "/").orderByChild("time").limitToLast(numberOfMessages+1).endAt(lastMessageTime); 
        } else {
            ref = this.db.ref("/conversations/messages/" + chatkey + "/").orderByChild("time").limitToLast(numberOfMessages+1);
        }

        ref.once("value", (snapshot) => {
            if(snapshot.exists()) {
                var messageCount = snapshot.numChildren();
                var numDecrypted = 0;
                snapshot.forEach((child) => {
                    if(!(lastMessageKey != null && child.key === lastMessageKey)) {
                        var messageJson = child.val();
                        decryptJSON(messageJson, ["msg"], (decryptedJSON) => { 

                            numDecrypted += 1;
                            //messageJson.chatkey = chat.chatkey;
                            //messageJson.messageUID = child.key;
                            //var message = new Message(decryptedJSON);
                            
                            
                            // TODO: Fix this code... missing an event here
                            //this.emit(UPDATE_CHAT_MESSAGE, chatkey, decryptedJSON, numDecrypted === messageCount);
                            
                            //   if(message.time > chat.lastCleared) {
                            //       chat.messages.push(message);
                            //   }
                            
                            //   if(numDecrypted == messageCount) {
                            //       getMessagesForChat(chat,true);
                            //   }
                        });
                    } else {
                        numDecrypted += 1; //we aren't including or decrypting the last message (endAt is inclusive) as we've already got it.
                    }
                });
                
            }
        });
    }

  // //HANDLE NOTIFICATIONS HERE
  // messageAdded(chat, messageJson) {
  //     var self = this;
  //     decryptJSON(messageJson, ["msg"], function(decryptedJSON) { 
  //         var message = new Message(decryptedJSON);
  //         if(message.time > chat.lastCleared) {
  //             if(message.type == MESSAGE_TYPE_NOTIFICATION) {
  //                 chat.lastMessage = message.text.replace("\n", "");
  //             } else if(message.type == MESSAGE_TYPE_WAYPOINT) {
  //                 if(chat.bIsGroupChat) {
  //                     chat.lastMessage = self.contactForUID(message.contactUID).username + ": shared a waypoint.";
  //                 } else {
  //                     chat.lastMessage = "Shared a waypoint.";
  //                 }
  //             } else if(message.type == MESSAGE_TYPE_CURRENT_LOCATION) {
  //                 if(chat.bIsGroupChat) {
  //                     chat.lastMessage = self.contactForUID(message.contactUID).username + ": shared their location.";
  //                 } else {
  //                     chat.lastMessage = "Shared location.";
  //                 }
  //             } else {
  //                 if(chat.bIsGroupChat) {
  //                     chat.lastMessage = message.contactUID !== self.user.uid ? self.contactForUID(message.contactUID).username + ": " + message.text : message.text;
  //                 } else {
  //                     chat.lastMessage = message.text;
  //                 }
  //             }
  //             chat.lastMessageDate = message.time;
  //             chat.messages.push(message);

  //             if(gActiveChat === chat.chatkey) {
  //                 selectChat(chat.chatkey); //this will rebuild the entire GUI, which isn't ideal - but testing for now
  //             }

  //             if(gChatView) {
  //                 gChatView.replaceInnerHTMLForChatRow(chat); //updates the last message text and date
  //             }
  //             self.sortChats();
  //         }
          
  //     });
  // }

  removeListenersForChatKey(chatKey) {

  }

//   createChatKeyForContacts(contactsArray) {
//       // console.log(contactsArray);
//       //1 on 1     = uid_uid (alphabetically)
//       //group chat = uid_NewUUID) 
//       var arrayLength = contactsArray.length;
//       if(arrayLength > 2) {
//           return this.user.uid + "_" + uuidv4();
//       }
//       else {
//           //case insensitive sort
//           contactsArray.sort(function(a, b){
//               var aUID = a.uid.toLowerCase();
//               var bUID = b.uid.toLowerCase();
//               if(aUID < bUID) {return -1;}
//               if(aUID > bUID) {return 1;}
//               return 0;
//           });

//           var key = "";
//           for(var i = 0; i < arrayLength; i++) {
//               var contact = contactsArray[i];
//               key += (key.length > 0) ? "_"+contact.uid : contact.uid;
//           }
          
//           return key;
//       }
//   }

  // getIncomingContactRequestsArray() {
  //     return this.contactRequests;
  // }

  // getOutgoingContactRequestsArray() {
  //     return this.outgoingRequests;
  // }

  // getContactsArray() {
  //     return this.contacts;
  // }

  // getChatsArray() {
  //     return this.chats;
  // }

  sendMessage(message, chatKey, messageType, additionalInfo, pinResource, pinBackgroundResource) {
      var json = {
          uid: this.user.uid,
          type: messageType,
          msg: message,
          time: firebase.database.ServerValue.TIMESTAMP
      };
      if(additionalInfo) {
          json["additionalinfo"] = additionalInfo;
      }
      if(pinResource) {
          json["pinresource"] = pinResource.includes(".png") ? pinResource : pinResource+".png";
      }
      if(pinBackgroundResource) {
          json["pinbackgroundresource"] = pinBackgroundResource.includes(".png") || pinBackgroundResource.length == 0 ? pinBackgroundResource : pinBackgroundResource+".png"
      }

      var self = this;
      encryptJSON(json, ["msg"], (encryptedJSON) => {
          var ref = self.db.ref("/conversations/messages/" + chatKey + "/" + DateHelpers.stringFromDate(moment().utc()) + "_" + this.user.uid + "/")
          ref.update(encryptedJSON);
      });
  }

  confirmContactWithUID(uid) {
      let path = `/requests/${this.user.uid}/${uid}/accepted`;
      console.log("confirmContactWithUID: accepting incoming request", path);
      this.db.ref(path).set(true);
  }

  createChatKeyForContacts(contactsArray) {
    // console.log(contactsArray);
    //1 on 1     = uid_uid (alphabetically)
    //group chat = uid_NewUUID) 
    var arrayLength = contactsArray.length;
    if(arrayLength > 2) {
        return this.user.uid + "_" + uuidv4();
    }
    else {
        //case insensitive sort
        contactsArray.sort(function(a, b){
            var aUID = a.uid.toLowerCase();
            var bUID = b.uid.toLowerCase();
            if(aUID < bUID) {return -1;}
            if(aUID > bUID) {return 1;}
            return 0;
        });

        var key = "";
        for(var i = 0; i < arrayLength; i++) {
            var contact = contactsArray[i];
            key += (key.length > 0) ? "_"+contact.uid : contact.uid;
        }
        
        return key;
    }
}

createGroupChatForContacts(contacts) {
    const chatKey = this.createChatKeyForContacts(contacts);
    const path = `/outgoingrequests/chats/${this.user.uid}/${chatKey}`;
    let data = { participants: {} };
    for(const key in contacts) {
        data.participants[contacts[key].uid] = true;
    }
    this.db.ref(path).set(data);
    console.log("createGroupChatForContacts: queued outgoing request", path, data);
}

  sendNotification(message, chatKey, fromUID) {
      var json = {
          uid: "notify_all",
          type: MESSAGE_TYPE_NOTIFICATION,
          msg: message,
          time: firebase.database.ServerValue.TIMESTAMP
      };

      var self = this;
      encryptJSON(json, ["msg"], function(encryptedJSON) {
          var ref = self.db.ref("/conversations/messages/"+ chatKey + "/" + DateHelpers.stringFromDate(moment().utc())+"_"+fromUID+"/")
          ref.update(encryptedJSON);
      });
  }


  deleteIncomingRequest(uid) {
      var contactRequestRef = this.db.ref("requests/"+this.user.uid+"/"+uid);
      var outgoingRequestRef = this.db.ref("outgoingrequests/"+uid+"/"+this.user.uid);
      contactRequestRef.remove();
      outgoingRequestRef.remove();
  }

  denyContactWithUID(uid) {
      this.deleteIncomingRequest(uid);
  }

  requestContact(contact) {
      let path = `/outgoingrequests/${this.user.uid}/${contact.uid}/processed`;
      this.db.ref(path).set(false);
      console.log("requestContact: queued outgoing request", path);
  }

    acceptBroadcastedLocationFromContactUID(contactUID) {
        return new Promise((resolve, reject) => {
            console.log("acceptedBroadcastedLocationFromContactUID: " + contactUID);
            var self = this;
            var sharingRef = self.db.ref("/sharedlocation/sharingwith/" + contactUID);
            sharingRef.once("value", function(snapshot) {
                if(snapshot.exists()) {
                    if(self.user.uid !== contactUID) {
                        var ref = self.db.ref("/sharedlocation/sharingwith/" + contactUID + "/" + self.user.uid);
                        ref.set(true, function(error) {
                            if(error) {
                                reject(error);
                            } else {
                                self.setContactViewing(contactUID, true);
                                resolve();
                            }
                        });
                    }
                }
            });
        })

    }

    setContactViewing(contactUID, bIsViewing) {
        var viewingRef = this.db.ref("/sharedlocation/viewing/" + this.user.uid + "/" + contactUID);
        viewingRef.set(bIsViewing);
    }

setLastViewed(chat) {
    var lastViewedRef = this.db.ref("/conversations/chats/" + chat.chatkey + "/lastviewed/" + this.user.uid);
    lastViewedRef.set(firebase.database.ServerValue.TIMESTAMP);
}


  clearChat(chat) {
      var update = {};
      update[this.user.uid] = firebase.database.ServerValue.TIMESTAMP;
      this.db.ref("/conversations/chats/"+chat.chatkey+"/lastcleared").update(update);
      chat.messages = new Array();
      chat.lastMessage = "";
  }

  addContactsToChat(contactsArray, chat) {
      var arrayLength = contactsArray.length;
      var participantsUpdate = {};
      var lastViewedUpdate = {};
      var lastClearedUpdate = {};
      var activeConvosUpdate = {};
      var participantsPath = "/conversations/chats/"+chat.chatkey+"/participants/";
      var lastViewedPath = "/conversations/chats/"+chat.chatkey+"/lastviewed/";
      var lastClearedPath = "/conversations/chats/"+chat.chatkey+"/lastcleared/";
      var addedUsersDict = new Object();


      for(var i = 0; i < arrayLength; i++) {
          var contact = contactsArray[i];
          if(!chat.containsContactUID(contact.uid)) {
              participantsUpdate[participantsPath + contact.uid] = true;
              lastViewedUpdate[lastViewedPath + contact.uid] = firebase.database.ServerValue.TIMESTAMP;
              lastClearedUpdate[lastClearedPath + contact.uid] = firebase.database.ServerValue.TIMESTAMP;
              activeConvosUpdate["/activeconversations/"+contact.uid+"/"+chat.chatkey] = true;
              addedUsersDict[contact.uid] = contact.username;
          }
      }
      if(Object.keys(participantsUpdate).length > 0) {
          var ref = this.db.ref();
          ref.update(participantsUpdate);
          ref.update(lastViewedUpdate);
          ref.update(lastClearedUpdate);
          ref.update(activeConvosUpdate);
      }

      for(var uid in addedUsersDict) {
          // console.log(addedUsersDict);
          if(chat.bIsGroupChat) {
              this.sendNotification(addedUsersDict[uid] + " has joined the group", chat.chatkey, uid);
          } else {
              this.sendNotification(addedUsersDict[uid] + " has added you", chat.chatkey, uid);
          }
      }
  }

  //use for group chats
  leaveGroupChat(chat) {
      this.sendNotification(this.getUserAsContact().username + " has left the group", chat.chatkey, this.user.uid);
      //no need to do these, the cloud function handles it.
      //delete from participants
      //delete last viewed

      //remove from activeconversations
      var ref = this.db.ref("/activeconversations/" + this.user.uid + "/" + chat.chatkey);
      ref.remove();
  }

  //not to be used for group chats
  deleteContactForChat(chat) {
      var length = chat.participants.length;
      for(var i = 0; i < length; i++) {
          var contact = chat.participants[i];
          if(contact.uid !== this.user.uid) {
              this.sendNotification(this.getUserAsContact().username + " has removed you as a contact.", chat.chatkey, this.user.uid);

              //delete contact
              var ref = this.db.ref("/contacts/" + this.user.uid + "/" + contact.uid);
              ref.remove();

              //also need to remove from activeconversations
              var convoRef = this.db.ref("/activeconversations/" + this.user.uid + "/" + chat.chatkey);
              convoRef.remove();

              this.removeSharingOfLocationToContact(contact);
              this.removeViewingOfLocationToContact(contact);
          }
      }
  }

  removeSharingOfLocationToContact(contact) {
      var ref = this.db.ref("/sharedlocation/sharingwith/" + this.user.uid + "/" + contact.uid);
      ref.remove();
  }

  removeViewingOfLocationToContact(contact) {
      var ref = this.db.ref("/sharedlocation/sharingwith/" + contact.uid + "/" + this.user.uid);
      ref.remove();
  }


  setGroupChatName(name, chatKey) {
    var ref = this.db.ref("/conversations/chats/" + chatKey + "/chatname");
    ref.set(name);
  }

  logout() {
    var onlineRef = this.db.ref("/users/"+this.user.uid+"/online_web");
    var seatRef = this.db.ref("/seats/"+this.user.uid+"/web/"+this.getInstanceID());
    onlineRef.set(false);
    seatRef.remove();
  }

  deleteAccount() {
      var ref = this.db.ref("/pending_delete/" + this.user.uid);
      ref.set(true, function() {
          firebase.auth().signOut();
      });
  }
  //window.deleteAccount = deleteAccount;

    updatePreference(preferenceName, val) {
        if(this.user != null && gSettings.provinceCode ) { 
            var preferencesRef = this.db.ref("/").child("sync/" + this.user.uid + "/preferences/" + gSettings.provinceCode  + "/" + preferenceName);
            preferencesRef.set(val);
        }
    }

  //we don't need broadcast sharing listener - just viewing.
  setupBroadcastViewingListeners() {
    var self = this;
    if(self.user != null) {

        var locationListenerRef = self.db.ref("/sharedlocation/viewing/"+self.user.uid)
             
        locationListenerRef.on("child_added", function(snapshot) {
            if(snapshot.exists()) {
                //var contact = self.getContactForUID(snapshot.key);
                var bLocationIsDisplayed = snapshot.val();
                if(bLocationIsDisplayed) {
                    self.listenToLocationsNodeForUID(snapshot.key);
                }
                // self.arrayOfContactsSharingLocation.push(contact);
                //notify broadcasting arrays updated...not sure we need this on web
            }
        });

        locationListenerRef.on("child_changed", function(snapshot) {
            if(snapshot.exists()) {
                var bLocationIsDisplayed = snapshot.val();
                if(bLocationIsDisplayed) {
                    self.listenToLocationsNodeForUID(snapshot.key);
                } else {
                    self.stopListeningToLocationsNodeForUID(snapshot.key);
                    // self.removeContactWithUidFromArray(contact.uid, self.arrayOfContactsSharingLocation);
                }
                //notify broadcasting arrays updated...not sure we need this on web
            }
        });

        locationListenerRef.on("child_removed", function(snapshot) {
            if(snapshot.exists()) {
                console.log("/sharedlocation/viewing/uid child_removed!");
                var contactUID = snapshot.key;
                // self.removeContactWithUidFromArray(contactUID, self.arrayOfContactsSharingLocation);
                self.stopListeningToLocationsNodeForUID(contactUID);
                //notify broadcasting arrays updated...not sure we need this on web
            }
        });

    }
    
}

listenToLocationsNodeForUID(uid) {
    console.log("listeningToLocationsNodeForUID: " + uid);
    var self = this;
    // this.stopListeningToLocationsNodeForUID(uid);
    var locationsRef = self.db.ref("/sharedlocation/locations/" + uid);
    locationsRef.on("child_added", (snapshot) => {
        if(snapshot.exists()) {
            self.showContactLocationForSnapshot(snapshot, uid, self.zoomToNextAddedBroadcastedLocation);
        }
    });

    locationsRef.on("child_changed", (snapshot) => {
        if(snapshot.exists()) {
            self.showContactLocationForSnapshot(snapshot, uid, false);
        }
    });

    //lets setup a listeners for online/offline
    // console.log("listening to: " + "/users/" + uid + "/online_web, online_ios, and online_android");
    var webRef = self.db.ref("/users/" + uid + "/online_web");
    webRef.on("value", (snapshot) => {
        if(snapshot.exists()) {
            // console.log("USER ONLINE/OFFLINE CHANGE: " + snapshot.val());
            if(snapshot.val()) {
                self.setContactStatus(uid,true);
            } else {
                self.checkUserStatus(uid);
            }
        }
    });

    var iosRef = self.db.ref("/users/" + uid + "/online_ios");
    iosRef.on("value", (snapshot) => {
        if(snapshot.exists()) {
            // console.log("USER ONLINE/OFFLINE CHANGE: " + snapshot.val());
            if(snapshot.val()) {
                self.setContactStatus(uid,true);
            } else {
                self.checkUserStatus(uid);
            }
        }
    });

    var androidRef = self.db.ref("/users/" + uid + "/online_android");
    androidRef.on("value", (snapshot) => {
        if(snapshot.exists()) {
            // console.log("USER ONLINE/OFFLINE CHANGE: " + snapshot.val());
            if(snapshot.val()) {
                self.setContactStatus(uid,true);
            } else {
                self.checkUserStatus(uid);
            }
        }
    });
}

setContactStatus(uid, bIsOnline) {
    this.emit(EVENT.SET_CONTACT_STATUS, uid, bIsOnline);   
}

//check if online on web...if yes, return
//if no, check if online android...if yes, return
//if no, check if online ios...return status
checkUserStatus(uid) {
    var self = this;
    console.log("checkingUserOnline For uid:" + uid);
    this.checkUserOnlineWeb(uid).then(function(result) {
        // console.log(result);
        if(result) {
            self.setContactStatus(uid,true);
        } else {
            self.checkUserOnlineAndroid(uid).then(function(result) {
                // console.log(result);
                if(result) {
                    self.setContactStatus(uid,true);
                } else {
                    self.checkUserOnlineIos(uid).then(function(result) {
                        // console.log(result);
                        self.setContactStatus(uid,result);
                    });
                }
            });
        }
    });
}

checkUserOnlineWeb(uid) {
    var ref = this.db.ref("/users/" + uid + "/online_web");
    return new Promise(function(resolve) {
        ref.once("value", function(snapshot) {
            if(snapshot.exists()) {
                resolve(snapshot.val());
            } else {
                resolve(false);
            }
        });
    });
}

checkUserOnlineIos(uid) {
    var ref = this.db.ref("/users/" + uid + "/online_ios");
    return new Promise(function(resolve) {
        ref.once("value", function(snapshot) {
            if(snapshot.exists()) {
                resolve(snapshot.val());
            } else {
                resolve(false);
            }
        });
    });
}

checkUserOnlineAndroid(uid) {
    var ref = this.db.ref("/users/" + uid + "/online_android");
    return new Promise(function(resolve) {
        ref.once("value", function(snapshot) {
            if(snapshot.exists()) {
                resolve(snapshot.val());
            } else {
                resolve(false);
            }
        });
    });
}

stopListeningToLocationsNodeForUID(uid) {
    console.log("stop listeningToLocationsNodeForUID: " + uid);
    var locationsRef = this.db.ref("/sharedlocation/locations/" + uid);
    locationsRef.off();

    this.emit(EVENT.REMOVE_CONTACT_MARKER, uid);

    console.log("stopped listening to: " + "/users/" + uid + "/online_web, online_ios, and online_android");
    var webRef = this.db.ref("/users/" + uid + "/online_web");
    webRef.off();
    var iosRef = this.db.ref("/users/" + uid + "/online_ios");
    iosRef.off();
    var androidRef = this.db.ref("/users/" + uid + "/online_android");
    androidRef.off();

}

stopViewingOfLocationOfContact(uid) {
    var sharingRef = this.db.ref("/sharedlocation/sharingwith/" + uid + "/" + this.user.uid);
    sharingRef.set(false);

    var viewingRef = this.db.ref("/sharedlocation/viewing/" + this.user.uid + "/" + uid);
    viewingRef.set(false);
}

showContactLocationForSnapshot(snapshot, uid) {
    // console.log(snapshot.val());
    var self = this;
    if(snapshot.key === FB_FREQUENCY_IN_SECONDS) {
        this.emit(EVENT.UPDATE_CONTACT_FREQUENCY, snapshot, uid);
    }
    else if(snapshot.key !== FB_LOCATION_DEVICE) {
        var locationJson = snapshot.val();
        decryptJSON(locationJson, ["latitude", "longitude"], (decryptedJSON) => {
            this.emit(EVENT.UPDATE_CONTACT_LOCATION, uid, decryptedJSON);
        });
    }
}


  setupDrawnAndTrackedWaypointListeners() {
    var ref = this.db.ref("/");
        //Drawn Waypoints
        var drawnWaypointRef = ref.child("sync/" + this.user.uid + "/drawnwaypoints/");
        // Attach an asynchronous callback to read the data at our posts reference
        drawnWaypointRef.on("child_added", (snapshot) => {
            var json = snapshot.val();
            decryptJSON(json, ["SpanLatitude", "SpanLongitude", "CenterLatitude", "CenterLongitude", "latitude", "longitude"], (decryptedJSON) => { 
                this.emit(EVENT.CREATE_DRAWN_WAYPOINT, decryptedJSON);
            } );
        }, function(){}, this);
    
        drawnWaypointRef.on("child_changed", (snapshot) => {
            var json = snapshot.val();
            decryptJSON(json, ["SpanLatitude", "SpanLongitude", "CenterLatitude", "CenterLongitude", "latitude", "longitude"], (decryptedJSON) => { 
                this.emit(EVENT.UPDATE_DRAWN_WAYPOINT, decryptedJSON);
            } );
        }, function(){}, this);

        drawnWaypointRef.on("child_removed", (snapshot) => {
            var json = snapshot.val();
            decryptJSON(json, ["SpanLatitude", "SpanLongitude", "CenterLatitude", "CenterLongitude", "latitude", "longitude"], (decryptedJSON) => { 
                this.emit(EVENT.DELETE_DRAWN_WAYPOINT, decryptedJSON);
            } );
        }, function(){}, this);


        //Tracked Waypoints
        var trackedWaypointRef = ref.child("sync/" + this.user.uid + "/trackedwaypoints_v2/");
        // Attach an asynchronous callback to read the data at our posts reference
        trackedWaypointRef.on("child_added", (snapshot) => {
            var json = snapshot.val();
            decryptJSON(json, ["SpanLatitude", "SpanLongitude", "CenterLatitude", "CenterLongitude", "latitude", "longitude"], (decryptedJSON) => { 
                this.emit(EVENT.CREATE_TRACKED_WAYPOINT, decryptedJSON);
            } );
        }, function(){}, this);
    
        trackedWaypointRef.on("child_changed", (snapshot) => {
            var json = snapshot.val();
            decryptJSON(json, ["SpanLatitude", "SpanLongitude", "CenterLatitude", "CenterLongitude", "latitude", "longitude"], (decryptedJSON) => { 
                this.emit(EVENT.UPDATE_TRACKED_WAYPOINT, decryptedJSON);
            } );
        }, function(){}, this);

        trackedWaypointRef.on("child_removed", (snapshot) => {
            var json = snapshot.val();
            decryptJSON(json, ["SpanLatitude", "SpanLongitude", "CenterLatitude", "CenterLongitude", "latitude", "longitude"], (decryptedJSON) => { 
                this.emit(EVENT.DELETE_TRACKED_WAYPOINT, decryptedJSON);
            } );
        }, function(){}, this);
    }

    setupWaypointListeners() {
        var ref = this.db.ref("/");
        var waypointRef = ref.child("sync/" + this.user.uid + "/waypoints/");
                    
        waypointRef.on("child_added", (snapshot) => {
            var json = snapshot.val();
            decryptJSON(json, ["latitude", "longitude"], (decryptedJSON) => { 
                this.emit(EVENT.CREATE_WAYPOINT, decryptedJSON);
            });
        });

        waypointRef.on("child_changed", (snapshot) => {
            var json = snapshot.val();
            decryptJSON(json, ["latitude", "longitude"], (decryptedJSON) => {
                this.emit(EVENT.UPDATE_WAYPOINT, decryptedJSON);
            } );
        });

        waypointRef.on("child_removed", (snapshot) => {
            var json = snapshot.val();
            decryptJSON(json, ["latitude", "longitude"], (decryptedJSON) => { 
                this.emit(EVENT.DELETE_WAYPOINT, decryptedJSON);
            });
        });
    }

    //FINISH ME!
    setLastWaypointImage(pinImageName, backgroundImageName) {
        if(pinImageName != null && pinImageName.length > 0) {
            this.updatePreference(FB_LAST_WAYPOINT_IMAGE,pinImageName);
        }
        if(backgroundImageName != null && backgroundImageName.length > 0) {
            this.updatePreference(FB_LAST_WAYPOINT_BACKGROUND,backgroundImageName);
        }
    }


    //name, desc lat, lon, pinResource, and images should all come from the add_waypoint_view modal
    addWaypoint(uuid, name, desc, lat, lon, pinResource, backgroundResource, imageNames) {
        var creationDateString = DateHelpers.stringFromDate(moment());
        var altitude = 0; //We could use the google API to get a json with the elevation... https://stackoverflow.com/questions/31839199/getting-altitude-from-latitude-and-longitude-here-api
        var accuracy = 0;
        this.updateWaypoint(uuid, name, desc, lat, lon, pinResource, backgroundResource, imageNames, altitude, accuracy, "", creationDateString, creationDateString, -1, false, "");        
    }   

    updateWaypoint(uuid, name, desc, lat, lon, pinResource, backgroundResource, imageNames, altitude, accuracy, creationUsername, creationDateString, updateDateString, moonphase, needsImageUpload, weatherImage) {
        
        var json = {
            UUID: uuid,
            accuracy: accuracy,
            altitude: altitude,
            creationUsername: creationUsername,
            date: creationDateString,
            dateUpdated: updateDateString,
            desc: desc,
            image: imageNames,
            latitude: lat.toString(),
            longitude: lon.toString(),
            moonphase: moonphase,
            name: name,
            needsImageUpload: needsImageUpload,
            pinResource: pinResource.replace("@2x","").replace(".png",""),
            pinBackgroundResource: backgroundResource.replace("@2x","").replace(".png",""),
            weather_image: weatherImage ? weatherImage : ""
        };
        var self = this;
        encryptJSON(json, ["latitude", "longitude"], function(encryptedJSON) {
            var ref = self.db.ref("sync/" + self.user.uid + "/waypoints/" + uuid + "/");
            ref.update(encryptedJSON);
        });
    }

    addDrawnWaypoint(uuid, name, desc, lat, lon, spanLat, spanLon, pinResource, imageNames, creationUsername, showArea, showDist, showElevation, annotationXML) {
        var creationDateString = DateHelpers.stringFromDate(moment());
        this.updateDrawnWaypoint(uuid, name, desc, lat, lon, spanLat, spanLon, pinResource, imageNames, creationUsername, creationDateString, creationDateString, false, showArea, showDist, showElevation, annotationXML);
    }

    updateDrawnWaypoint(uuid, name, desc, lat, lon, spanLat, spanLon, pinResource, imageNames, creationUsername, creationDateString, updateDateString, needsImageUpload, showArea, showDist, showElevation, annotationXML) {
        if(imageNames == null) {
            imageNames = "";
        }
        var json = {
            AnnotationXML: annotationXML,
            CenterLatitude: lat.toString(),
            CenterLongitude: lon.toString(),
            SpanLatitude: spanLat.toString(),
            SpanLongitude: spanLon.toString(),
            UUID: uuid,
            creationUsername: creationUsername,
            date: creationDateString,
            dateUpdated: updateDateString,
            desc: desc,
            drawnwaypointShowArea: showArea,
            drawnwaypointShowDist: showDist,
            drawnwaypointShowElevation: showElevation,
            image: imageNames,
            latitude: lat.toString(),
            longitude: lon.toString(),
            name: name,
            needsImageUpload: needsImageUpload,
            pinResource: pinResource,
            };
        var self = this;
        encryptJSON(json, ["CenterLatitude", "CenterLongitude", "SpanLatitude", "SpanLongitude", "latitude", "longitude"], function(encryptedJSON) {
            var ref = self.db.ref("sync/" + self.user.uid + "/drawnwaypoints/" + uuid + "/");
            ref.update(encryptedJSON);
        });  
    }
    
    updateTrackedWaypoint(uuid, name, desc, lat, lon, spanLat, spanLon, pinResource, imageNames, creationUsername, creationDateString, updateDateString, needsImageUpload, annotationXML) {
        var json = {
            AnnotationXML: annotationXML,
            CenterLatitude: lat.toString(),
            CenterLongitude: lon.toString(),
            SpanLatitude: spanLat.toString(),
            SpanLongitude: spanLon.toString(),
            UUID: uuid,
            creationUsername: creationUsername,
            date: creationDateString,
            dateUpdated: updateDateString,
            desc: desc,
            image: imageNames,
            name: name,
            needsImageUpload: needsImageUpload,
            pinResource: pinResource,
            };
        var self = this;
        encryptJSON(json, ["CenterLatitude", "CenterLongitude", "SpanLatitude", "SpanLongitude"], function(encryptedJSON) {
            var ref = self.db.ref("sync/" + self.user.uid + "/trackedwaypoints_v2/" + uuid + "/");
            ref.update(encryptedJSON);
        });  
    }

    setWaypointImage(filename, waypointUUID) {
        //populate the images node (We use this to download images to devices).
        var ref = this.db.ref("sync/" + this.user.uid + "/images/" + waypointUUID + "/" + filename);
        ref.update({
            imageFilename: filename
        });
    }

    removeWaypointImage(filename, waypointUUID) {
        var ref = this.db.ref("sync/" + this.user.uid + "/images/" + waypointUUID + "/" + filename);
        ref.remove();
    }

    deleteWaypointImages(waypointUUID) {
        //get a list of images from the rtdb
        //delete each image one by one as we can't delete the entire folder
        //when a deletion occurs, delete that node from the rtdb too
        var ref = this.db.ref("sync/" + this.user.uid + "/images/" + waypointUUID);
        ref.once("value", (snapshot) => {
            if(snapshot != null) {
                snapshot.forEach(async (child) => {
                    var filename = child.key;
                    await this.service.storage.deleteImage(filename, waypointUUID);

                    this.removeWaypointImage(filename, waypointUUID);
                });
            }
        });
    }

    deleteWaypoint(uuid) {
        this.deleteWaypointImages(uuid);
        var ref = this.db.ref("sync/" + this.user.uid + "/waypoints/" + uuid + "/");
        var deletedWaypointsRef = this.db.ref("sync/" + this.user.uid + "/deletedwaypoints/" + uuid + "/");
        this.moveWaypointToDeleted(ref, deletedWaypointsRef);
    }

    deleteDrawnWaypoint(uuid) {
        this.deleteWaypointImages(uuid);
        var ref = this.db.ref("sync/" + this.user.uid + "/drawnwaypoints/" + uuid + "/");
        var deletedWaypointsRef = this.db.ref("sync/" + this.user.uid + "/deletedwaypoints/" + uuid + "/");
        this.moveWaypointToDeleted(ref, deletedWaypointsRef);
    }

    deleteTrackedWaypoint(uuid) {
        this.deleteWaypointImages(uuid);
        let ref = this.db.ref("sync/" + this.user.uid + "/trackedwaypoints_v2/" + uuid + "/");
        let deletedWaypointsRef = this.db.ref("sync/" + this.user.uid + "/deletedwaypoints/" + uuid + "/");
        this.moveWaypointToDeleted(ref, deletedWaypointsRef);
        ref = this.db.ref("sync/" + this.user.uid + "/trackedwaypoints_light/" + uuid + "/");
        ref.remove();
    }

    moveWaypointToDeleted(waypointRef, deletedRef) {
        waypointRef.once("value", function(snapshot) {
            if(snapshot != null) {
                var waypointJson = snapshot.val(); //creates json 
                deletedRef.update({
                    UUID: waypointJson.UUID
                })
                waypointRef.remove();
            }
        });
    }

    setShowDistForWaypoint(uuid, bShow) {
        var ref = this.db.ref("sync/" + this.user.uid + "/drawnwaypoints/" + uuid + "/");
        ref.update({drawnwaypointShowDist: bShow, dateUpdated: DateHelpers.stringFromDate(moment())});
    }
    
    setShowAreaForWaypoint(uuid, bShow) {
        var ref = this.db.ref("sync/" + this.user.uid + "/drawnwaypoints/" + uuid + "/");
        ref.update({drawnwaypointShowArea: bShow, dateUpdated: DateHelpers.stringFromDate(moment())});
    }

    setShowElevationsForWaypoint(uuid, bShow) {
        var ref = this.db.ref("sync/" + this.user.uid + "/drawnwaypoints/" + uuid + "/");
        ref.update({drawnwaypointShowElevation:bShow, dateUpdated: DateHelpers.stringFromDate(moment())});
    }

    updateDrawnWaypointAnnotation(uuid, annotationXML) {
        var ref = this.db.ref("sync/" + this.user.uid + "/drawnwaypoints/" + uuid + "/");
        ref.update({AnnotationXML:annotationXML, dateUpdated: DateHelpers.stringFromDate(moment())});
    }


    acceptClipboardWaypoint(waypointUUID, sendersUID) {
        var ref = this.db.ref("/clipboard/"+sendersUID+"/waypoints/"+waypointUUID+"/accepted/"+this.user.uid);
        ref.set(true);
    }

    copyFromClipboard(waypointUUID, sendersUID) {

        console.log("copyFromClipboard("+waypointUUID+","+sendersUID+")");
        var ref = this.db.ref("/clipboard/"+sendersUID+"/waypoints/"+waypointUUID+"/type");
        ref.once("value", (snapshot) => {
            if(snapshot.exists()) {
                var waypointType = snapshot.val();
                var newRefPath = this.getWaypointPathFromType(waypointType, waypointUUID);
                var clipboardRefPath = "/clipboard/"+sendersUID+"/waypoints/"+waypointUUID+"/"+waypointUUID;
                
                var clipboardRef = this.db.ref(clipboardRefPath);
                var newRef = this.db.ref(newRefPath);
                clipboardRef.once("value", (snap) => {
                    if(snap.exists()) {
                        newRef.set(snap.val(),() => {
                            this.service.storage.copyImagesAtPath(clipboardRefPath, newRefPath, waypointUUID, sendersUID);
                            this.acceptClipboardWaypoint(waypointUUID, sendersUID);
                        });
                    }
                });

            }
        }); 
    }

    getWaypointPathFromType(waypointType, waypointUUID) {
        if(waypointType === FB_TYPE_WAYPOINT) {
            return "/sync/"+this.user.uid+"/waypoints/"+waypointUUID;
        } else if(waypointType === FB_TYPE_TRACKEDWAYPOINT) {
            return "/sync/"+this.user.uid+"/trackedwaypoints_v2/"+waypointUUID;
        }
        return "/sync/"+this.user.uid+"/drawnwaypoints/"+waypointUUID;
    }

    shareWaypoints(waypoints, chat, pinImage) {

        //var arrayOfWaypoints = new Array();
        if(waypoints && waypoints.length > 0) {
            var numWaypoints = waypoints.length;
            var waypointsString = null;
            for(var i = 0; i < numWaypoints; i++) {
                let wp = waypoints[i];

                waypointsString = waypointsString != null ? wp.uuid + ","+waypointsString : wp.uuid;

                let waypointType = wp.getFirebaseType();
                this.populateClipboardWithWaypoint(wp, waypointType, chat);
            }
            
            var bounds = this.getBoundsForWaypoints(waypoints);
            var boundsString = null;
            if(bounds != null) {
                boundsString = bounds.getCenter().lat + "," + bounds.getCenter().lng + "," + Math.abs(bounds.getNorth() - bounds.getSouth()).toString() + "," + Math.abs(bounds.getEast() - bounds.getWest()).toString(); //center y, center x, span y, span x; //center lat, center lon, span lat, span lon;
            }

            //send the message to firebase
            this.sendMessage(waypointsString, chat.chatkey, MESSAGE_TYPE_WAYPOINT, boundsString, pinImage);
        }
    }

    populateClipboardWithWaypoint(wp, waypointType, chat) {
        var self = this;
        var clipboardRef = this.db.ref("/clipboard/"+self.user.uid+"/waypoints/"+wp.uuid+"/"+wp.uuid);
        var originalRef = this.db.ref(this.getWaypointPathFromType(waypointType, wp.uuid));

        
        originalRef.once("value", function(snapshot) {
            if(snapshot.exists()) {
                var json = snapshot.val();
                if(!Object.prototype.hasOwnProperty.call(json, "creationUsername") || json["creationUsername"] === "") {
                    json["creationUsername"] = self.getUserAsContact().username;
                }
                clipboardRef.set(json,function(error) {
                    //set accepted node
                    //we should also check to see if this exists already first. if it does, we don't want to erase existing users.
                    var acceptedRef = self.db.ref("/clipboard/"+self.user.uid+"/waypoints/"+wp.uuid+"/accepted");
                    acceptedRef.once("value",function(snap) {
                        var acceptedJson = {};
                        if(snap.exists()) {
                            acceptedJson = snap.val();
                        }
                        for(var i = 0; i < chat.activeParticipants.length; i++) {
                            var contact = chat.activeParticipants[i];
                            if(contact.uid !== self.user.uid && !Object.prototype.hasOwnProperty.call(acceptedJson, contact.uid)) {
                                acceptedJson[contact.uid] = false;
                            }
                        }
                        acceptedRef.update(acceptedJson);
                    });

                    //set the waypoint type
                    var waypointTypeRef = self.db.ref("/clipboard/"+self.user.uid+"/waypoints/"+wp.uuid+"/type");
                    waypointTypeRef.set(waypointType);

                    //set the expiration date to 30 days from now
                    var waypointExpirationRef = self.db.ref("/clipboard/"+self.user.uid+"/waypoints/"+wp.uuid+"/expirationDate");
                    waypointExpirationRef.set(DateHelpers.stringFromDate(moment().utc().add(30,'days')));
                });
            } 
        }); 
    }

    getBoundsForWaypoints(waypoints) {
        var numWaypoints = waypoints.length;
        var totalBounds = null;
        for(var i = 0; i < numWaypoints; i++) {
            var waypoint = waypoints[i];
            totalBounds = totalBounds ? totalBounds.extend(waypoint.getBounds()) : waypoint.getBounds();
        }
        return totalBounds;
    }


    reconstructSku(key) {
        return key.replace(/,/g,".");
    }

    // Email stuff seems out of place
    sendEmail(textToSend) {
        var data = '&userEmailInput=info@ihunterapp.com';
        data += '&userNameInput=' + (this.user.displayName ? this.user.displayName : "Unknown Name");
        data += '&messageInput=' + textToSend;
        data += '&uid=' + (this.user.uid ? this.user.uid : "Not logged in");
        data += '&province=' + (gSettings.provinceCode ? gSettings.provinceCode : "No active province set yet");
        data += '&browser=' + getBrowserInfo();
        data += '&production=' + isProduction();
        data += '&webpurch=' + (gSettings ? getPurchasesString(gSettings.purchasesWeb) : "N/A");
        data += '&iospurch=' + (gSettings ? getPurchasesString(gSettings.purchasesIOS) : "N/A");
        data += '&andpurch=' + (gSettings ? getPurchasesString(gSettings.purchasesAndroid) : "N/A");

        $.ajax({
            type: "POST",
            url: "sendEmail.php",
            data: data,
            success: function(r){
                console.log(r);
                // toast("Thank you for the email. We will reply as soon as possible."); 
            },
            error: function(r){
                console.log(r);
                // toast("There was an issue sending your email. Please email info@ihunterapp.com."); 
            }
        });
    }











    async getProductPrice(sku) {
        var ref = this.db.ref(`/product/prices/${sku}`);
        let snapshot = await ref.once("value");
        if(snapshot.exists()) {
            return snapshot.val();
        } else {
            return '?';
        }
    }
}

<style lang="scss" scoped>
    @import './css/common.scss';

    .app-info {
        z-index: 1000; // Put above map tools
        position: absolute;
        white-space: pre-line;
        height:auto;
        width:100vw;

        .modal-dialog {
            max-width: 600px;
            margin: auto;
        }

        .ihunter-icon {
            height: 150px;
            border-radius: 20px;
        }

        .modal-header {
            background: rgb(51, 51, 51);
            font-size: 12px;
            color: white;
            .close {
                margin: -1rem;
            }
        }
        .modal-body {
            font-size: 10px;
            height:auto;
            flex-grow: 1;
        }

        .modal-content {
            top: 5vh;
            max-height: 90vh;
        }

        .modal-footer {
            background: rgb(51, 51, 51);
        }

        .app-header {
            display: flex;
            flex-direction: row;
            flex-grow: 1;
        }   

        .app-props {
            display: flex;
            flex-direction: column;

            margin-left: 20px;
            margin-right: 20px;

            .app-name {
                font-size: 15px;
                font-weight: bold;
            }

            .app-copy {
                font-size: 10px;
            }

            button {
                margin-top: 20px;
            }
        }
    }
</style>

<template>

    <div class="app-info" v-if="visible">
            <div class="modal-dialog full" role="document">
                <div class="modal-content">
                    <div class="modal-header full">
                        <div class="app-header">
                            <img class="ihunter-icon" :src="icon"/>

                            <div class="app-props">
                                <span class="app-name">iHunter {{this.provinceName}}</span>
                                <span>App Version: {{this.appVersion}}</span>
                                <span>Regs Version: {{this.regVersion}}</span>
                                <span class="app-copy">Ⓒ {{this.appAuthor}}</span>

                                <button v-on:click="email()" class="btn btn-primary" type="button" data-dismiss="modal">Email Us</button>
                            </div>
                        </div>
                        <a href="javascript:void(0)" class="closebtn rightSidebar" v-on:click="close()">×</a>
                    </div>
                    <div class="modal-body full">
                        
                        <div></div>
                        <div v-html="this.notice"></div>
                        <br>
                        <div>iHunter Web App utilizes <a href="licenses.txt" target="_blank">3rd party software</a> licensed under MIT, BSD, and other terms.
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button v-on:click="close()" class="btn btn-primary" type="button" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import anchorme from "anchorme";

    import build from "../package.json";
    import { gSettings } from './Settings';

    @Component
    export default class AppInfo extends Vue {

        visible = false;
        provinceCode = gSettings.provinceCode;

        icon = `../images/ihunter_logo_${this.provinceCode}_square.png`;
        
        config = {};

        mounted() {
            this.loadConfig();
        }
        
        async loadConfig() {
            let config = await this.getRegionConfig(this.provinceCode);

            for (let property in config) {
                this.$set(this.config, property, config[property]);
            }
        }

        async getRegionConfig(region) {
            let query = `./res/provinces/${region}/province.json`;

            let options = {
                method: 'GET',
                headers: {
                    'Accept': 'application/json'
                }
            };

            try {
                let response = await fetch(query, options);
                return response.json();
                
            }catch(error) {
                throw error;
            }
        }

        get appVersion() {
            return build.version;
        }

        get appAuthor() {
            return build.author;
        }

        get notice() {
            return this.config?.STR_IP_TEXT ? anchorme({input: this.config?.STR_IP_TEXT}) : '';
        }

        get regVersion() {
            return this.config?.regulations_version;
        }

        get provinceName() {
            return this.config?.PROVINCE_NAME;
        }

        show() {
            this.visible = true;
        }

        close() {
            this.visible = false;
        }

        email() {
            window.onEmailUs();
        }
    }
</script>
